// @flow

import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import classNames from 'classnames';

import AdminCategoryFragment from '~/admin/shared/AdminCategoryFragment';
import SelectInput from '~/admin/shared/components/SelectInput';

type Category = {
  id: string,
  lineage: string,
  measurementsRequired: string,
};

type Props = {
  categories: Array<Category>,
  onChange: () => void,
  label: string,
  loading: boolean,
  value: Category,
  isHighlighted: boolean,
  version: number,
  disabled?: boolean,
};


class CategorySelect extends Component<Props> {
  render() {
    const className = classNames(
      'qa-category-select',
      {
        'is-highlighted': this.props.isHighlighted,
      }
    );

    if (this.props.loading) {
      return null;
    } else {
      return (
        <SelectInput
          {...this.props}
          className={className}
          id="category"
          labelKey="lineage"
          options={this.props.categories}
          valueKey="id"
          placeholder="Browse Full List"
          disabled={this.props.disabled}
        />
      );
    }
  }
}

const withAttributionCategories = graphql(
  gql`
    query fetchAttributionCategories($version: Int) {
      categories(forAttribution: true, version: $version) {
        ...AdminCategoryFragment
      }
    }

    ${AdminCategoryFragment}
  `,
  {
    options: ({ version }) => ({
      variables: {
        version: version,
      },
    }),
    props: ({ data: { loading, categories } }) => {
      return { loading, categories };
    },
  },
);

export default compose(
  withAttributionCategories
)(CategorySelect);
