// @flow

import gql from 'graphql-tag';

const AdminCategoryFragment = gql`
  fragment AdminCategoryFragment on Category {
    id
    lineage
    measurementsRequired
    attributionHelperMessage {
      textHtml
    }

    showItemProperties
    lowValueRequireDescription
    midValueRequireDescription
    highValueRequireDescription
    showPublicMeasurements
  }
`;

export default AdminCategoryFragment;
