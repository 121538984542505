// @flow

import React, { Component } from 'react';
import { Creatable } from 'react-select';
import basicInputWrapper from './basicInputWrapper';

export type MultiSelectOption<T> = {
  label: string,
  value: T,
  type?: string,
};

type Props<T> = {
  id: string,
  onChange: (Array<MultiSelectOption<T>>) => void,
  options: Array<MultiSelectOption<T>>,
  value: Array<MultiSelectOption<T>>,
  disabled?: boolean,
};

class CreateableMultiSelectInput<T> extends Component<Props<T>> {
  static defaultProps = {
    options: [],
  };

  handleChange = (options: Array<MultiSelectOption<T>>) => {
    this.props.onChange(options.map((option): MultiSelectOption<T> => {
      const type = option.value === option.label ? 'other' : 'id';

      return {
        ...option,
        type,
      };
    }));
  }

  shouldCreateOption = ({ keyCode }) => {
    switch (keyCode) {
      case 9: // TAB
      case 13: // ENTER
      case 186: // SEMICOLON
        return true;

      default:
        return false;
    }
  }

  render() {
    const {
      id,
      value,
      disabled,
      ...otherProps
    } = this.props;

    const newValue = value.map((v) => {
      if (v.type === 'other') {
        return { label: v.value, value: v.value };
      } else {
        return v.value;
      }
    });

    return (
      <div id={id} className="custom-select form-control__input">
        <Creatable
          {...otherProps}
          multi
          onChange={this.handleChange}
          shouldKeyDownEventCreateNewOption={this.shouldCreateOption}
          value={newValue}
          disabled={disabled}
        />
      </div>
    );
  }
}

export default basicInputWrapper(CreateableMultiSelectInput);
