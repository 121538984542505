// @flow

import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';

import SelectInput from '~/admin/shared/components/SelectInput';

type Props = {
  errors: Array<string>,
  required: boolean,
  disabled?: boolean,
  item: {
    valuation: {
      id: String,
    }
  },
  onChange: (Object) => any,

  itemValuationQuery: {
    error: boolean,
    loading: Boolean,
    itemValuations: Array<any>,
  },
};

class EstimatedValueSelect extends Component<Props> {
  itemValuations = [];

  onChange = (option: any) => {
    const valuation = this.itemValuations.find((val) => val.id === option.value);
    this.props.onChange(valuation || this.itemValuations[0]);
  }

  render() {
    const {
      required, errors, item, itemValuationQuery: { error, loading, itemValuations }, disabled,
    } = this.props;
    let options = [];

    if (!error && !loading) {
      this.itemValuations = itemValuations;
      options = [...itemValuations].map((option) => ({ value: option.id, label: option.shortText }));
    }

    return (
      <div className="l-grid__item l-grid__item--2-to-4">
        <SelectInput
          allowCreate={false}
          errors={errors}
          id="estimated_value"
          label="Manually Estimated Value"
          priorityLabel={required && 'Required'}
          onChange={(value) => this.onChange(value)}
          options={options}
          value={item.valuation ? item.valuation.id : null}
          disabled={disabled}
        />
      </div>
    );
  }
}

const ITEM_VALUATION_QUERY = gql`
  query fetchItemValuation {
    itemValuations {
      id
      shortText
      estimatedValue
    }
  }
`;

export default compose(
  graphql(ITEM_VALUATION_QUERY, {
    props: ({ data }) => ({ itemValuationQuery: data }),
  })
)(EstimatedValueSelect);
