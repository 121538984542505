// @flow

import React, { Fragment, type StatelessFunctionalComponent } from 'react';
import moment from 'moment';
import TimesyncContext from '../contexts/TimesyncContext';
import ItemThumbnail from './ItemThumbnail';
import { Badge, TimeRemaining } from '~/public/shared/components';
import formatCurrency from '~/utils/formatCurrency';

import type { Item } from '../types';

export const ItemListing: StatelessFunctionalComponent<{
  item: Item,
  gridStyle: string,
}> = ({
  item,
  gridStyle,
}) => {
  const renderStatus = () => {
    const {
      processStatus,
      saleStartsAt,
      saleEndsAt,
      // yankReasonDisplayName,
      yankReasonDescription,
      yankReasonExternalDisplayName,
      aasmState,
    } = item;

    const isLive = processStatus === 'live';
    let badgeStyle;
    let badgeTooltip;
    let statusText;

    if (isLive) {
      badgeStyle = 'positive';
    } else if ((['processing', 'preview', 'in_appraisal'].includes(processStatus))) {
      badgeStyle = 'neutral';
    } else if (processStatus === 'omitted') {
      badgeStyle = 'warning';
    }

    if (yankReasonExternalDisplayName) {
      const description = yankReasonDescription ? <div className="u-mt1">{yankReasonDescription}</div> : null;
      badgeTooltip = <Fragment><strong>{yankReasonExternalDisplayName}</strong>{description}</Fragment>;
    }

    if (processStatus === 'ended') {
      statusText = moment(saleEndsAt).format('M/DD/YY');
    } else if (!['omitted', 'live'].includes(processStatus) && saleStartsAt) {
      statusText = `Starts ${moment(saleStartsAt).format('M/DD/YY')}`;
    }

    return (
      <Fragment>
        <Badge
          status={badgeStyle}
          infoTooltip={badgeTooltip}
          style={{ textTransform: 'capitalize' }}
          className={(statusText || isLive) ? 'u-mr1' : ''}
        >
          {processStatus === 'in_appraisal' ? 'In Appraisal' : processStatus}
        </Badge>
        {isLive &&
          <TimesyncContext.Consumer>
            {(timesync) => (
              <TimeRemaining
                extended={aasmState === 'extended'}
                endsAt={moment(saleEndsAt)}
                aasmState={aasmState}
                getReferenceTime={timesync.now}
              />
            )}
          </TimesyncContext.Consumer>
        }
        {statusText &&
          <span className="u-text-muted u-no-wrap">{statusText}</span>
        }
      </Fragment>
    );
  };

  const {
    barcode,
    mainAttachment,
    quickpicAttachment,
    name,
    highBidAmount,
    processStatus,
  } = item;

  const showBidInfo = ['live', 'ended'].includes(processStatus);

  let attachment = null;
  let showTempBadge = false;

  if (mainAttachment) {
    attachment = mainAttachment;
  } else if (quickpicAttachment) {
    attachment = quickpicAttachment;
    showTempBadge = true;
  }

  return (
    <div className="index-listing__data-row" style={{ gridTemplateColumns: gridStyle }}>
      <span className="index-listing__cell index-listing__cell--thumbnail">
        <ItemThumbnail attachment={attachment} showTempBadge={showTempBadge} alt={name} />
      </span>
      <span className="index-listing__cell">
        <strong>{name}</strong>
        <div className="u-text-muted">{barcode}</div>
      </span>
      <span className="index-listing__cell">{renderStatus()}</span>
      <span className="index-listing__cell">
        {showBidInfo ? <h4>{formatCurrency(highBidAmount)}</h4> : <span className="u-text-muted">N/A</span>}
      </span>
    </div>
  );
};
