// @flow

import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Card } from '../Card';
import { LoadingDots } from '~/public/shared/components';
import { FeatureFlagConsumer } from '../../contexts/FeatureFlagContext';

const STATUS = Object.freeze({
  initial: 'initial',
  loading: 'loading',
  requested: 'requested',
});

type Status = $Values<typeof STATUS>

type Props = {
};

type State = {
  status: Status,
};

type User = {
  id: number,
  receivedW9: boolean,
}

const CURRENT_USER_QUERY = gql`
  {
    currentUser {
      id
      receivedW9
    }
  }
`;

export class TaxDocumentOnFile extends Component<Props, State> {
  state: State = {
    status: STATUS.initial,
  }

  renderW9Link(user: User) {
    switch (this.state.status) {
      case STATUS.loading:
        return <LoadingDots />;
      case STATUS.requested:
        return <span>Request sent</span>;
      default:
      case STATUS.initial:
        if (!user.receivedW9) {
          return <FeatureFlagConsumer>
            {(featureFlags) => {
              if (featureFlags.requestW9Help) {
                return (
                  <a
                    className="underline"
                    onClick={async () => {
                      try {
                        const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');
                        const csrfToken = csrfTokenElement ? csrfTokenElement.getAttribute('content') : null;
                        this.setState({
                          status: STATUS.loading,
                        });
                        const response = await fetch('/users/sell/resend-w9', {
                          body: JSON.stringify({
                          }),
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                            'X-Requested-With': 'XMLHttpRequest',
                            ...csrfToken ? { 'X-CSRF-Token': csrfToken } : {},
                          },
                        });
                        if (response.status >= 200 && response.status <= 299) {
                          this.setState({
                            status: STATUS.requested,
                          });
                          return;
                        }
                      } catch (err) {
                        console.error(err);
                      }
                      this.setState({
                        status: STATUS.initial,
                      });
                    }}
                  >Request W-9 be resent</a>
                );
              } else {
                return null;
              }
            }}
          </FeatureFlagConsumer>;
        }
        break;
    }
    return null;
  }

  render() {
    return (
      <Query query={CURRENT_USER_QUERY}>
        {({ loading, error, data }) => {
          if (loading || error) {
            return <LoadingDots />;
          }
          const user = data.currentUser;
          return <Card header={`W-9 received? ${user.receivedW9 ? 'Yes' : 'No'}`} className="u-mt2 tax-document-on-file">
            <div className="u-flex column">
              {this.renderW9Link(user)}
              <a href="/1099faq">FAQs</a>
            </div>
          </Card>;
        }}
      </Query>
    );
  }
}
