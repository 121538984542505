// @flow
/* eslint-disable react/no-multi-comp */

import React, { Component } from 'react';

// FIXME: dont depend on another screens type
import { type AdditionalInformationLinkType } from '~/admin/cataloging/types';

import TextInput from '~/admin/shared/components/TextInput';
import { Button } from '~/admin/shared/components';
import ActionSvgIcon from '~/admin/shared/svgs/ActionSvgIcon';

const NEW_LINK = { id: null, title: '', href: '' };

type AdditionalInformationLinkProps = {
  link: AdditionalInformationLinkType,
  onChange: (AdditionalInformationLinkType) => any,
  onDelete: () => any,
  disabled?: boolean,
};
class AdditionalInformationLink extends Component<AdditionalInformationLinkProps> {
  handleChange = (key: string) => (value: string) => {
    this.props.onChange({
      ...this.props.link,
      [key]: value,
    });
  };

  render = () => {
    const { link, onDelete, disabled } = this.props;

    // If the user has any input
    const hasInput = link.title.length > 0 || link.href.length > 0;
    const titleErrs = (link.title.length === 0 && hasInput) ? ['Title cannot be blank'] : [];
    const hrefErrs = (link.href.length === 0 && hasInput) ? ['Href cannot be blank'] : [];

    return (
      <div className="additional-link u-flex">
        <div className="additional-link__fields">
          <div className="u-flex">
            <TextInput
              id="link-title"
              className="form-input"
              label="Link Title"
              placeholder="e.g. EBTH"
              onChange={this.handleChange('title')}
              errors={titleErrs}
              value={link.title}
              disabled={disabled}
            />
            <TextInput
              id="link-url"
              className="form-input"
              label="URL"
              placeholder="e.g. http://ebth.com"
              onChange={this.handleChange('href')}
              errors={hrefErrs}
              value={link.href}
              disabled={disabled}
            />
          </div>
        </div>
        <Button
          onClick={onDelete}
          className="title-tooltip title-tooltip--left u-flex-ase u-mb2"
          data-title="Delete"
          icon={<ActionSvgIcon icon="ic_delete_forever_24px" className="button__icon" />}
          disabled={disabled}
        />
      </div>
    );
  };
}

type Props = {
  additionalInformationLinks: Array<AdditionalInformationLinkType>,
  onChange: (Array<AdditionalInformationLinkType>) => any,
  disabled?: boolean,
};
class AdditionalInformationLinksForm extends Component<Props> {
  handleAddLink = () => {
    this.props.onChange([...this.props.additionalInformationLinks, NEW_LINK]);
  };

  handleChange = (index: number) => (value: AdditionalInformationLinkType) => {
    const links = this.props.additionalInformationLinks;

    this.props.onChange([...links.slice(0, index), value, ...links.slice(index + 1)]);
  };

  handleDelete = (index: number) => () => {
    const links = this.props.additionalInformationLinks;

    this.props.onChange([...links.slice(0, index), ...links.slice(index + 1)]);
  };

  render = () => {
    // Treat the empty state as a single new link for quick entry
    const links =
      this.props.additionalInformationLinks.length === 0
        ? []
        : this.props.additionalInformationLinks;

    return (
      <div>
        {links.map((link, index) => (
          <AdditionalInformationLink
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            link={link}
            onChange={this.handleChange(index)}
            onDelete={this.handleDelete(index)}
            disabled={this.props.disabled}
          />
        ))}

        <Button
          onClick={this.handleAddLink}
          buttonStyle="link"
          className="u-pl0 u-pb0"
          size="compact"
          disabled={this.props.disabled}
        >
          Add an Additional Link
        </Button>
      </div>
    );
  };
}

export default AdditionalInformationLinksForm;
